<template>
  <div>
    <v-card-text>
      <v-alert v-if="!documentReady" text type="info"
        >No hay data para mostrar</v-alert
      >
      <v-card v-else outlined class="white pa-2">
        <v-data-table
          item-key="Id"
          :headers="headers"
          :items="items"
          :loading="processing"
          :items-per-page="5"
          show-expand
          :expanded.sync="expanded"
          single-expand
        >
          <template v-slot:item.Fecha="{ item }">{{
            item.Fecha | date
          }}</template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="background: lightgrey">
              <br />
              <v-card id="detalle" tile outlined class="white pa-2">
                <v-data-table
                  item-key="AsociadoCobroFacturasDetalleID"
                  :headers="headersDetalle"
                  :items="item.AsociadoCobroFacturasDetalle"
                  :items-per-page="5"
                >
                  <template v-slot:item.FechaCobro="{ item }">{{
                    item.FechaCobro | date
                  }}</template>
                </v-data-table>
              </v-card>
              <br />
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-select
          :items="periodoOptions"
          v-model="periodoSelected"
          item-text="NombreMes"
          item-value="FechaInicio"
          append-icon="keyboard_arrow_down"
          hint="Filtrar por fecha"
          hide-details
          :loading="processing"
        ></v-select>
        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="get">
            <v-icon left>search</v-icon>Buscar
          </v-btn>
          <v-btn
            class="ma-1"
            depressed
            block
            :loading="loadingDownload"
            @click="download"
            :disabled="items.length == 0"
          >
            Exportar
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiAsociado from "@/api/asociados";
import { mapActions } from "vuex";
import moment from "moment";
import download from "downloadjs";
export default {
  data: () => {
    return {
      drawerRight: true,
      periodoSelected: null,
      items: [],
      headers: [
        { text: "SUCURSAL", value: "CodSucursal" },
        { text: "FECHA", value: "Fecha" },
        { text: "CANTIDAD FACTURA COBRADA", value: "CantidadFacturas" },
      ],
      headersDetalle: [
        { text: "BAN", value: "BAN" },
        { text: "TELEFONO", value: "Telefono" },
        { text: "FECHA COBRO", value: "FechaCobro" },
      ],
      processing: false,
      expanded: [],
      loadingDownload: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
    documentReady() {
      return this.items.length > 0;
    },
  },
  methods: {
    ...mapActions(["setMessage"]),
    get() {
      this.processing = true;
      this.items = [];
      apiAsociado
        .getAsociadoCobrosFacturas(
          this.user.asociado.canalAsociadoId,
          moment(this.periodoSelected).format("YYYY-MM-DD")
        )
        .then((response) => {
          this.items = response.data;
          this.processing = false;
        })
        .catch((error) => {
          this.processing = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    download() {
      this.loadingDownload = true;

      apiAsociado
        .getAsociadoCobrosFacturasDownload(
          this.user.asociado.canalAsociadoId,
          moment(this.periodoSelected).format("YYYY-MM-DD")
        )
        .then((response) => {
          response.data;
          download(response.data, "NumerosCobrados" + ".xlsx");
          this.loadingDownload = false;
        })
        .catch((error) => {
          this.loadingDownload = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
  },
};
</script>